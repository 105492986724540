import React from 'react';
import { Elements } from 'prismic-reactjs';
import { Description } from '../../components/sections/Projects/projects.styles';

const propsWithUniqueKey = (props: object, key: any) =>
  Object.assign(props || {}, { key });

// TODO: type of this function
export const projectsHtmlSerializer = (
  type: Elements,
  element: any,
  content: string,
  children: any[],
  key: string
): any => {
  let props = {};

  switch (type) {
    case Elements.paragraph:
      return (
        <Description {...propsWithUniqueKey(props, key)}>
          {children}
        </Description>
      );

    default:
      return null;
  }
};
