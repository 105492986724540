import styled from '@emotion/styled';
import { defaultContainerSize, mq } from '../../../styles/mixins';
import { css } from '@emotion/react';
import { Header2, ParagraphS } from '../../core/Typography/Typography';

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  background: #f4f4f4;
  padding: 4rem 0;

  ${mq.mobileMax(css`
    padding: 7.5rem 0;
  `)}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2.5rem;

  ${defaultContainerSize};

  ${mq.medium(css`
    align-items: center;
  `)};
`;

export const Title = styled.h2`
  ${Header2};
  color: ${({ theme }) => theme.colors.black};
  text-align: left;
  margin: 0;

  ${mq.medium(css`
    text-align: center;
  `)}
`;

export const Description = styled.p`
  ${ParagraphS};
  margin: 0;
  letter-spacing: 0.0125rem;

  ${mq.medium(css`
    text-align: center;
  `)};
`;

export const ProjectsWrapper = styled.div`
  max-width: 48.125rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1.875rem;

  ${mq.mobileMax(css`
    flex-direction: row;
  `)}
`;
