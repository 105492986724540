import * as React from 'react';
import { RichText } from 'prismic-reactjs';
import Link from 'next/link';
import { IProjectSummary } from '../interfaces';
import {
  ProjectButton,
  ProjectImage,
  ProjectTitle,
  ProjectWrapper,
  ProjectDetails,
  ProjectButtonArrow,
} from './project.styles';
import { getCaseStudyLink } from './project.utils';
import { imageSerializer } from '../../../../utils/imageSerializer';
import Image from 'next/image';

export const Project = ({ project }: IProjectSummary) => {
  const title = RichText.asText(project.title);

  return (
    <Link id={title} key={title} href={getCaseStudyLink(title)}>
      <ProjectWrapper>
        <ProjectImage>
          <Image {...imageSerializer(project.image)} />
        </ProjectImage>
        <ProjectDetails>
          <ProjectTitle>{RichText.asText(project.visible_title)}</ProjectTitle>
          <ProjectButton>
            {RichText.asText(project.see_project)}
            <ProjectButtonArrow>
              <svg
                width="22"
                height="12"
                viewBox="0 0 22 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.66968 6H20.1697M20.1697 6L15.1697 1M20.1697 6L15.1697 11"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </ProjectButtonArrow>
          </ProjectButton>
        </ProjectDetails>
      </ProjectWrapper>
    </Link>
  );
};
