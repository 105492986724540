import { RichText } from 'prismic-reactjs';

import { IProjectsProps, IProjectSummary } from './interfaces';
import { Project } from './Project/Project';
import { ProjectsWrapper, Section, Title, Wrapper } from './projects.styles';
import { projectsHtmlSerializer } from '../../../utils/htmlSerializers/projectsHtmlSerializer';

export const Projects = ({
  items,
  title,
  description,
  titleAs,
}: IProjectsProps): JSX.Element => {
  return (
    <Section>
      <Wrapper>
        <Title as={titleAs}>{title}</Title>
        {description && (
          <RichText
            render={description}
            htmlSerializer={projectsHtmlSerializer}
          />
        )}
        <ProjectsWrapper>
          {items.map((item: IProjectSummary) => (
            <Project key={RichText.asText(item.project.title)} {...item} />
          ))}
        </ProjectsWrapper>
      </Wrapper>
    </Section>
  );
};
