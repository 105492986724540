import styled from '@emotion/styled';
import { boxShadow } from '../../../../styles/mixins';
import { ParagraphL } from '../../../core/Typography/Typography';

export const ProjectButton = styled.button`
  max-width: 13rem;
  border: none;
  border-radius: 3.75rem;
  padding: 0.5rem 1.8125rem;
  color: ${({ theme }) => theme.colors.core_white};
  text-decoration: none;
  font-family: ${({ theme }) => theme.font.questrial};
  background-color: ${({ theme }) => theme.colors.core_black};
  transition: background-color 300ms ease-in-out;
  text-align: center;
  font-size: 1.25rem;
  line-height: 2rem;
  letter-spacing: 0.0125rem;
  cursor: pointer;
  position: relative;
  box-shadow: none;
`;

export const ProjectWrapper = styled.div`
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.core_white};
  text-decoration: none;
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 23.125rem;

  ${boxShadow};

  &:hover {
    ${ProjectButton} {
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }

  &:after {
    border-radius: 0.5rem;
    box-shadow: 0.5rem 0.5rem 1.5rem rgba(23, 0, 89, 0.29);
  }

  @media (min-width: 852px) {
    margin-bottom: 0;
  }
`;

export const ProjectTitle = styled.p`
  ${ParagraphL};
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.font.questrial};
  line-height: 2rem;
  font-weight: 400;
  margin: 0 0 1.5rem 0;
`;

export const ProjectImage = styled.div`
  width: 100%;
  height: 100%;
  max-height: 16rem;

  img {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    max-height: 16rem;
    width: 100%;
    height: 100%;
  }
`;

export const ProjectDetails = styled.div`
  padding: 1.5rem 1.5rem 2rem 1.5rem;
`;

export const ProjectButtonArrow = styled.span`
  margin-left: 0.625rem;
`;
